import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { useToast } from "../../../components";
import { formatPhoneNumber } from "../../../utils/string";
import {
  CurrentUserDocument,
  CurrentUserFragment,
  CurrentUserQuery,
  useVerifyPhoneSignInMutation,
} from "../../graphql";

interface VerifySignInFormProps {
  phoneNumber: string;
  phoneNumberExtension: string | null;
  channel: "SMS" | "CALL";
  onSignIn: (currentUser: CurrentUserFragment) => void;
  onResendVerification: () => void;
}

const VerifySignInForm: React.FC<VerifySignInFormProps> = ({
  phoneNumber,
  phoneNumberExtension,
  channel,
  onSignIn,
  onResendVerification,
}) => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    verificationCode: string;
  }>();

  const [verifySignIn, { loading }] = useVerifyPhoneSignInMutation({
    update(cache, { data }) {
      const currentUser = data?.verifyPhoneSignIn?.currentUser;
      if (currentUser) {
        cache.writeQuery<CurrentUserQuery>({
          query: CurrentUserDocument,
          data: { currentUser },
        });
      }
    },
    onCompleted: ({ verifyPhoneSignIn }) => {
      const currentUser = verifyPhoneSignIn?.currentUser;
      if (!currentUser) {
        return;
      }
      onSignIn(currentUser);
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const onSubmit = handleSubmit(({ verificationCode }): void => {
    verifySignIn({
      variables: { phoneNumber, phoneNumberExtension, verificationCode },
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <Flex direction="column" alignItems="center">
        <Box mb={4}>
          {channel === "SMS" ? (
            <>
              A text with a verification code was sent to{" "}
              <strong>{phoneNumber}</strong>.
            </>
          ) : (
            <>
              An automated call was made to{" "}
              <strong>
                {formatPhoneNumber(phoneNumber, phoneNumberExtension)}
              </strong>
              . You will be asked to enter a number between 0 and 9. Then you
              will be given a verification code.
            </>
          )}
        </Box>
        <FormControl
          id="verificationCode"
          width="160px"
          isRequired
          isInvalid={errors.verificationCode !== undefined}
        >
          <FormLabel>Verification code</FormLabel>
          <Input
            {...register("verificationCode")}
            autoFocus
            autoComplete="one-time-code"
            minLength={6}
            maxLength={6}
          />
          {errors.verificationCode !== undefined && (
            <FormErrorMessage>
              {errors.verificationCode.message}
            </FormErrorMessage>
          )}
        </FormControl>
        <ButtonGroup spacing={4} mt={6} mx="auto">
          <Button type="submit" isLoading={loading}>
            Sign in
          </Button>
          <Button
            variant="outline"
            onClick={onResendVerification}
            isDisabled={loading}
          >
            Try again
          </Button>
        </ButtonGroup>
      </Flex>
    </form>
  );
};

export default VerifySignInForm;
