import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { Alert, LoadingIndicator, useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  MetricName,
  UpdateAnalyticsQualityReportConfigMutationVariables as FormData,
  useAnalyticsQualityReportConfigQuery,
  useResetAnalyticsQualityReportConfigMutation,
  useUpdateAnalyticsQualityReportConfigMutation,
} from "../../graphql";
import { REPORT_METRICS } from "./const";
import useAnalyticsContext from "./useAnalyticsContext";
import { isMetricSupportedForOrg } from "./utils";

type FormField = {
  formFieldKey: keyof FormData;
  label: string;
  description: string;
  metric: MetricName;
};

const FORM_FIELDS = REPORT_METRICS as FormField[];

const AnalyticsReportEditModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  refetchAllData: () => void;
}> = ({ isOpen, onClose, refetchAllData }) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const { atsDataState } = useAnalyticsContext();

  const { data, loading } = useAnalyticsQualityReportConfigQuery();
  const settings = data?.currentUser?.organization?.qualityReportConfig;
  const {
    agendaSetEnabled,
    candidateQuestionOpportunityEnabled,
    candidateTalkRatioEnabled,
    onTimeStartsEnabled,
    problematicQuestionsEnabled,
    longestMonologueEnabled,
    speakingRateEnabled,
    interactivityEnabled,
    questionsAskedEnabled,
    feedbackSubmissionRateEnabled,
    feedbackSubmissionTimeEnabled,
  } = settings || {};

  const [updateConfig, { error }] =
    useUpdateAnalyticsQualityReportConfigMutation({
      onCompleted: (data) => {
        if (data?.updateAnalyticsQualityReportConfig?.reportConfig) {
          toast({
            status: "success",
            title: "Report configuration saved",
          });
          onClose();
          refetchAllData();
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Report configuration",
          description: "There was a problem - please try again",
        });
      },
      refetchQueries: ["AnalyticsQualityReportConfig"],
    });

  const [resetConfig] = useResetAnalyticsQualityReportConfigMutation({
    onCompleted: (data) => {
      if (data?.resetAnalyticsQualityReportConfig?.reportConfig) {
        toast({
          status: "success",
          title: "Report configuration reset",
        });
        onClose();
        refetchAllData();
      }
    },
    onError: () => {
      toast({
        status: "error",
        title: "Report configuration",
        description: "There was a problem - please try again",
      });
    },
    refetchQueries: ["AnalyticsQualityReportConfig"],
  });

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      agendaSetEnabled,
      candidateQuestionOpportunityEnabled,
      candidateTalkRatioEnabled,
      onTimeStartsEnabled,
      problematicQuestionsEnabled,
      longestMonologueEnabled,
      speakingRateEnabled,
      interactivityEnabled,
      questionsAskedEnabled,
      feedbackSubmissionRateEnabled,
      feedbackSubmissionTimeEnabled,
    },
  });

  return (
    <Modal closeOnOverlayClick isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="960px">
        <ModalHeader>Configure Report</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <>
            <Text color="gray.600" mt="2" mb="4" fontSize="sm">
              Choose which metrics to include in the interview quality score. If
              data is unavailable for a metric then it will not be included in
              the calculation.
            </Text>
            {loading && <LoadingIndicator />}
            {!loading && (
              <form
                autoComplete="on"
                onSubmit={handleSubmit((formData) => {
                  sendGAEvent("report_config", "analytics", "update");
                  updateConfig({ variables: formData });
                })}
              >
                {error?.graphQLErrors?.map(({ message }, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Alert key={i} status="error" description={message} />
                ))}
                <VStack align="start" spacing="0">
                  {FORM_FIELDS.map(
                    ({ formFieldKey: key, label, description, metric }) => (
                      <FormControl
                        key={key}
                        id={key}
                        display="flex"
                        alignItems="start"
                        minHeight="64px"
                        py="2"
                        hidden={!isMetricSupportedForOrg(metric, atsDataState)}
                      >
                        <Switch
                          {...register(key)}
                          defaultChecked={settings?.[key]}
                          mr="6"
                        />
                        <FormLabel
                          minWidth="200px"
                          mr="6"
                          mb="0"
                          color="gray.800"
                          fontWeight="400"
                          fontSize="sm"
                        >
                          {label}
                        </FormLabel>
                        <FormHelperText
                          color="gray.800"
                          fontWeight="400"
                          fontSize="sm"
                          mt="0"
                          mb="0"
                          lineHeight="20px"
                        >
                          {description}
                        </FormHelperText>
                      </FormControl>
                    )
                  )}
                </VStack>
                <Flex justifyContent="space-between" mt="10">
                  <Button
                    variant="outline"
                    borderColor="transparent"
                    size="sm"
                    fontWeight="400"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Flex alignItems="center">
                    <Button
                      variant="outline"
                      borderColor="transparent"
                      size="sm"
                      fontWeight="400"
                      onClick={() => resetConfig({ variables: {} })}
                      mr="4"
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="solid"
                      isLoading={loading}
                      data-testid="save"
                      size="sm"
                      fontWeight="400"
                    >
                      Save
                    </Button>
                  </Flex>
                </Flex>
              </form>
            )}
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AnalyticsReportEditModal;
