import { useMemo, useState } from "react";

import { dateRange90DaysAgo } from "../../../components/SelectDateRange/SelectDateRange";
import {
  useEnumSearchParam,
  useNumberSearchParam,
  useSelectDateRangeParams,
  useStringArrayParam,
} from "../../../hooks/useSearchParam";
import { formatDateRange } from "../../../utils/datetime";
import { snakeCaseToTitleCase } from "../../../utils/string";
import { AnalyticsDimension, MetricName } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import {
  AnalyticsConfig,
  ChartLimit,
  ChartSort,
  ReportColumn,
  ReportSortDirection,
  SelectOption,
} from "./types";
import useAnalyticsContext from "./useAnalyticsContext";
import { isDimensionSupported } from "./utils";

const defaultPrimaryDimension = AnalyticsDimension.Interviewer;
const defaultSecondaryDimension = AnalyticsDimension.None;
const defaultChartSort: ChartSort = "highest-first";
const defaultChartLimit = 20;

/**
 * Manages the state of user-selectable analytics configurations such as
 * filters, dimensions, and chart settings.
 *
 * Only manages the config, but not the query execution.
 */
const useAnalyticsConfig = (
  metric: MetricName,
  defaultDateRange = dateRange90DaysAgo
): AnalyticsConfig => {
  const [primaryDimension, setPrimaryDimension] =
    useEnumSearchParam<AnalyticsDimension>("dimension");
  const [secondaryDimension, setSecondaryDimension] =
    useEnumSearchParam<AnalyticsDimension>("segment");
  const [chartLimit, setChartLimit] = useNumberSearchParam("limit");
  const [chartSort, setChartSort] = useEnumSearchParam<ChartSort>("sort");
  const [positions, setPositions] = useStringArrayParam("positions");
  const [interviewers, setInterviewers] = useStringArrayParam("interviewers");
  const [interviewerLabels, setInterviewerLabels] = useState<
    string[] | undefined
  >(undefined);
  const [departments, setDepartments] = useStringArrayParam("departments");
  const [stages, setStages] = useStringArrayParam("stages");
  const [dateRange, setDateRange] = useSelectDateRangeParams(
    "dateRange",
    defaultDateRange()
  );
  const [reportPage, setReportPage] = useNumberSearchParam("page");
  const [reportSortColumn, setReportSortColumn] =
    useEnumSearchParam<ReportColumn>("sortColumn", "score");
  const [reportSortDirection, setReportSortDirection] =
    useEnumSearchParam<ReportSortDirection>("sortDirection", "desc");

  const filters = {
    positions: positions || [],
    interviewers: interviewers || [],
    departments: departments || [],
    stages: stages || [],
  };

  // Flagged feature: Group by performance for HCA
  const hcaMetricsEnabled = useFeatureFlag("analytics:hca-metrics");

  const displayDateRangeValue = useMemo(() => {
    if (dateRange) {
      return formatDateRange(dateRange.start, dateRange.end);
    }
    return "";
  }, [dateRange]);

  const primaryDimensionValue = primaryDimension || defaultPrimaryDimension;
  const secondaryDimensionValue =
    secondaryDimension || defaultSecondaryDimension;

  const { atsDataState } = useAnalyticsContext();
  const stageSupported = isDimensionSupported(
    AnalyticsDimension.JobStage,
    metric,
    atsDataState
  );
  const statusFlagOn = useFeatureFlag("analytics:status-segmentation");
  const statusSupported =
    statusFlagOn &&
    isDimensionSupported(
      AnalyticsDimension.ApplicationStatus,
      metric,
      atsDataState
    );

  return {
    metric,
    primaryDimension: {
      value: primaryDimensionValue,
      setValue: setPrimaryDimension,
      options: primaryDimensionOptions({
        groupByStage: stageSupported,
        groupByPerformance: hcaMetricsEnabled,
      }),
    },
    secondaryDimension: {
      value: secondaryDimensionValue,
      setValue: setSecondaryDimension,
      options: secondaryDimensionOptions({
        segmentByStatus: statusSupported,
      }),
    },
    chartLimit: {
      value: chartLimit || defaultChartLimit,
      setValue: setChartLimit,
      options:
        metric === MetricName.Report
          ? chartLimitOptions()
          : chartLimitOptions(primaryDimensionValue),
      afterValueChange:
        metric === MetricName.Report ? () => setReportPage(1) : undefined,
    },
    chartSort: {
      value: chartSort || defaultChartSort,
      setValue: setChartSort,
      options: chartSortOptions(hcaMetricsEnabled),
    },
    positions: {
      values: positions,
      setValues: setPositions,
    },
    interviewers: {
      values: interviewers,
      setValues: setInterviewers,
      labels: interviewerLabels,
      setLabels: setInterviewerLabels,
    },
    departments: {
      values: departments,
      setValues: setDepartments,
    },
    stages: {
      values: stages,
      setValues: setStages,
    },
    dateRange: {
      // Default is defined inline because it dynamically calculates a range
      value: dateRange,
      displayValue: displayDateRangeValue,
      setValue: setDateRange,
    },
    report: {
      page: {
        value: reportPage || 1,
        setValue: setReportPage,
      },
      sortColumn: {
        value: reportSortColumn || "score",
        setValue: setReportSortColumn,
      },
      sortDirection: {
        value: reportSortDirection || "desc",
        setValue: setReportSortDirection,
      },
    },
    filters,
    enabledFilters: {
      positions: true,
      interviewers: true,
      departments: true,
      stages: stageSupported,
    },
  };
};

export const chartSortOptions = (
  hcaMetricsEnabled?: boolean
): SelectOption<ChartSort>[] => {
  const options: SelectOption<ChartSort>[] = [
    { value: "highest-first", label: "Highest Number First" },
    { value: "lowest-first", label: "Lowest Number First" },
    { value: "most-interviews-first", label: "Most Interviews First" },
    { value: "least-interviews-first", label: "Fewest Interviews First" },
    { value: "name-a-z", label: "Name A-Z" },
    { value: "name-z-a", label: "Name Z-A" },
  ];
  if (hcaMetricsEnabled) {
    const performanceOptions: SelectOption<ChartSort>[] = [
      { value: "highest-performance-first", label: "Highest Performance" },
      { value: "lowest-performance-first", label: "Lowest Performance" },
    ];
    return options.concat(...performanceOptions);
  }
  return options;
};

export const renameDimension = (stage: string): string =>
  stage.replace(/job/gi, "Interview");

export const chartLimitOptions = (
  primaryDimension?: AnalyticsDimension
): SelectOption<ChartLimit>[] => {
  const labelSuffix = primaryDimension
    ? ` ${snakeCaseToTitleCase(renameDimension(primaryDimension))}s`
    : "";
  return [
    {
      value: 20,
      label: `20${labelSuffix}`,
    },
    {
      value: 50,
      label: `50${labelSuffix}`,
    },
    {
      value: 100,
      label: `100${labelSuffix}`,
    },
    {
      value: -1,
      label: `All${labelSuffix}`,
    },
  ];
};

export const primaryDimensionOptions = (features: {
  groupByStage: boolean;
  groupByPerformance: boolean;
}): SelectOption<AnalyticsDimension>[] =>
  [
    { value: AnalyticsDimension.Interviewer, label: "Interviewer" },
    { value: AnalyticsDimension.Department, label: "Department" },
    { value: AnalyticsDimension.Position, label: "Position" },
  ]
    .concat(
      features.groupByStage
        ? [{ value: AnalyticsDimension.JobStage, label: "Interview Stage" }]
        : []
    )
    .concat(
      features.groupByPerformance
        ? [{ value: AnalyticsDimension.Performance, label: "Performance" }]
        : []
    );

export const secondaryDimensionOptions = (features: {
  segmentByStatus: boolean;
}): SelectOption<AnalyticsDimension>[] =>
  [
    { value: AnalyticsDimension.None, label: "None" },
    { value: AnalyticsDimension.Gender, label: "Candidate Gender" },
  ].concat(
    features.segmentByStatus
      ? [
          {
            value: AnalyticsDimension.ApplicationStatus,
            label: "Application Status",
          },
        ]
      : []
  );

export default useAnalyticsConfig;
