import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";

import { SelectDateRangeState } from "../../../components/SelectDateRange/SelectDateRange";
import { formatDateRange } from "../../../utils/datetime";
import {
  Alert,
  AnalyticsDimension,
  AtsDataState,
  MetricName,
  ReportDataPoint,
} from "../../graphql";
import { calculateAverage } from "./averages";
import { ATS_METRIC_NAMES, ATS_REQUIRED_DIMENSIONS } from "./const";
import { ValueFormat } from "./MetricConfig";
import {
  DataPoint,
  LineChartDataPoint,
  ReportColumn,
  ReportSortDirection,
} from "./types";

export const isScorecardMetric = (metric: MetricName): boolean => {
  return ATS_METRIC_NAMES.includes(metric);
};

export const isAtsRequiredDimension = (
  dimension: AnalyticsDimension
): boolean => {
  return ATS_REQUIRED_DIMENSIONS.includes(dimension);
};

// scorecard metrics require ATS data
export const isMetricSupportedForOrg = (
  metric: MetricName,
  atsDataState: AtsDataState
): boolean => {
  if (!isScorecardMetric(metric)) return true;
  return atsDataState !== AtsDataState.AtsNotSupported;
};

// scorecard metrics do not support application status filtering
export const isDimensionSupportedForMetric = (
  dimension: AnalyticsDimension,
  metric: MetricName
): boolean => {
  if (!isScorecardMetric(metric)) return true;
  return dimension !== AnalyticsDimension.ApplicationStatus;
};

// job stage and application status filtering/grouping require ATS data
export const isDimensionSupportedForOrg = (
  dimension: AnalyticsDimension,
  atsDataState: AtsDataState
): boolean => {
  if (!isAtsRequiredDimension(dimension)) return true;
  return atsDataState !== AtsDataState.AtsNotSupported;
};

export const isDimensionSupported = (
  dimension: AnalyticsDimension,
  metric: MetricName,
  atsDataState: AtsDataState
): boolean => {
  return (
    isDimensionSupportedForOrg(dimension, atsDataState) &&
    isDimensionSupportedForMetric(dimension, metric)
  );
};

const unitsCondensed = {
  seconds: "s",
  minutes: "m",
  hours: "h",
  days: "d",
};

const getUnitsVerbose = (plural: boolean): { [key: string]: string } => {
  return {
    seconds: ` second${plural ? "s" : ""}`,
    minutes: ` minute${plural ? "s" : ""}`,
    hours: ` hour${plural ? "s" : ""}`,
    days: ` day${plural ? "s" : ""}`,
  };
};

type FormatOptions = {
  verbose?: boolean;
};

export type StructuredFormattedValue = {
  value: number | string;
  unitSymbol: string;
};

export const getStructuredFormatFromValue = (
  value: number | null | undefined,
  format: ValueFormat,
  options?: FormatOptions
): StructuredFormattedValue[] => {
  const verbose = options?.verbose;

  let obj: StructuredFormattedValue = {
    value: "",
    unitSymbol: "",
  };

  // null case - lint-friendly check
  if (!value && value !== 0) {
    return [obj];
  }
  // default case
  obj = {
    value: value.toFixed(2),
    unitSymbol: "",
  };
  // formats
  if (format === "percent") {
    obj = {
      value: (value * 100).toFixed(0),
      unitSymbol: "%",
    };
  }
  if (format === "seconds") {
    const roundedVal = Math.round(value);
    const units = verbose ? getUnitsVerbose(roundedVal !== 1) : unitsCondensed;
    obj = {
      value: roundedVal,
      unitSymbol: units.seconds,
    };
  }
  if (format === "minutes") {
    const roundedVal = Math.round((value || 0) / 60.0);
    const units = verbose ? getUnitsVerbose(roundedVal !== 1) : unitsCondensed;
    obj = {
      value: roundedVal,
      unitSymbol: units.minutes,
    };
  }
  if (format === "hours") {
    const roundedVal = Math.round((value || 0) / 3600.0);
    const units = verbose ? getUnitsVerbose(roundedVal !== 1) : unitsCondensed;
    obj = {
      value: roundedVal,
      unitSymbol: units.hours,
    };
  }
  // Prefer whole hours if possible, but if the roundedVal is less than 1, opt to show 1 decimal place.
  if (format === "hours-granular") {
    const roundedVal = (value || 0) / 3600.0;
    const units = verbose ? getUnitsVerbose(roundedVal !== 1) : unitsCondensed;
    let finalVal =
      roundedVal < 1 ? roundedVal.toFixed(1) : Math.round(roundedVal);
    if (finalVal === "0.0") {
      finalVal = "0";
    }
    obj = {
      value: finalVal,
      unitSymbol: units.hours,
    };
  }
  if (format === "hours-minutes") {
    const totalMinutes = Math.round((value || 0) / 60.0);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const hourUnits = verbose ? getUnitsVerbose(hours !== 1) : unitsCondensed;
    const minuteUnits = verbose
      ? getUnitsVerbose(minutes !== 1)
      : unitsCondensed;

    if (hours === 0) {
      return [
        {
          value: minutes,
          unitSymbol: minuteUnits.minutes,
        },
      ];
    }

    const result = [
      {
        value: hours,
        unitSymbol: hourUnits.hours,
      },
      {
        value: minutes,
        unitSymbol: minuteUnits.minutes,
      },
    ];
    return result;
  }
  if (format === "days-hours") {
    const roundedHours = Math.round((value || 0) / 3600.0); // hours
    const days = roundedHours / 24;
    const roundedDays = Math.floor(days);
    const roundedRemainderHours = Math.round((days - roundedDays) * 24 || 0);
    const dayUnits = verbose
      ? getUnitsVerbose(roundedDays !== 1)
      : unitsCondensed;
    const hourUnits = verbose
      ? getUnitsVerbose(roundedRemainderHours !== 1)
      : unitsCondensed;
    const result = [
      {
        value: roundedDays,
        unitSymbol: dayUnits.days,
      },
    ];
    if (roundedRemainderHours > 0) {
      result.push({
        value: roundedRemainderHours,
        unitSymbol: hourUnits.hours,
      });
    }
    return result;
  }
  if (format === "integer") {
    obj = {
      value: Math.round(value),
      unitSymbol: "",
    };
  }

  return [obj];
};

export const formatAnalyticsDateRange = (
  dateRange: SelectDateRangeState
): {
  current: string;
  previous: string;
} => {
  if (dateRange.type === "custom") {
    return {
      current: formatDateRange(dateRange.start, dateRange.end),
      previous: "Previous period",
    };
  }
  if (dateRange.type === "last_6m") {
    return {
      current: "Last 6 months",
      previous: "Previous period",
    };
  }
  const [prefix, number] = dateRange.type.split("_");
  const current = `${
    prefix.charAt(0).toUpperCase() + prefix.slice(1)
  } ${number} days`;

  return {
    current,
    previous: "Previous period",
  };
};

export const formatValue = (
  value: number | null | undefined,
  format: ValueFormat,
  options?: FormatOptions
): string => {
  const structuredValue = getStructuredFormatFromValue(value, format, options);
  let result = "";
  structuredValue.forEach((v, i) => {
    result += `${v.value}${v.unitSymbol}`;
    if (i + 1 < structuredValue.length) {
      result += " ";
    }
  });
  return result;
};

type FilterMap = {
  position: string[];
  client: string[];
  interviewer: string[];
  stage: string[];
};

export const mapFiltersToAlgoliaURL = (
  analyticsConfig: {
    primaryDimension: { value: AnalyticsDimension };
    dateRange: { value: { start: Date; end: Date } };
    filters: {
      positions: string[];
      departments: string[];
      stages: string[];
      interviewers: string[];
    };
    interviewers: { labels?: string[] };
  },
  entry: { label?: string | null },
  metric: MetricName
): string => {
  const {
    primaryDimension: { value: primaryDimension },
    dateRange,
    filters,
  } = analyticsConfig;

  const dimension =
    primaryDimension === AnalyticsDimension.Department
      ? "client"
      : primaryDimension === AnalyticsDimension.JobStage
      ? "stage"
      : primaryDimension.toLocaleLowerCase();
  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.getAll("source");
  source.push("insights");
  const sourceParams = `source=${[...new Set(source)].join("&source=")}`;

  let url = `/search?${sourceParams}&metric=${metric}&${dimension}=${encodeURIComponent(
    entry.label || ""
  )}&date%5Bmin%5D=${Number.parseInt(
    format(dateRange.value.start, "t")
  )}&date%5Bmax%5D=${Number.parseInt(format(dateRange.value.end, "t"))}`;

  // add filters to url conditionally
  // in some cases, bigquery and algolia work with different params
  // i.e. id instead of name
  // in those cases, raw label will return the label without count
  const filtersMap: FilterMap = {
    position: filters.positions,
    client: filters.departments,
    interviewer: analyticsConfig.interviewers.labels || [],
    stage: filters.stages,
  };

  const filterKeys = Object.keys(filtersMap);
  filterKeys.forEach((filter: string) => {
    if (dimension !== filter) {
      filtersMap[filter as keyof typeof filtersMap].forEach((value: string) => {
        url += `&${filter}=${encodeURIComponent(value)}`;
      });
    }
  });
  return url;
};

export const groupBySegment = (
  dataPoints: DataPoint[] = []
): { [key: string]: DataPoint[] } => {
  const segments: { [key: string]: DataPoint[] } = {};
  dataPoints.forEach((p) => {
    const segment = p.segment || "all";
    if (segments[segment]) {
      segments[segment].push(p);
    } else {
      segments[segment] = [p];
    }
  });
  return segments;
};

export type MetricStats = {
  totalInterviews: number;
  numXValues: number;
  metricAverageValue: number;
  metricTotalValue: number;
};
// TODO: Write tests for this util
export const calculateMetricStats = (
  dataPoints: DataPoint[] = [],
  metric: MetricName
): MetricStats => {
  const totalInterviews = dataPoints.reduce(
    (prev, cur) => prev + (cur.countDataPoints || 0),
    0
  );

  const metricDefinedValues: number[] = dataPoints
    .map((d) => d.value)
    .filter((v) => v !== undefined && v !== null) as number[];
  const metricTotalValue = metricDefinedValues.reduce(
    (prev, cur) => prev + cur,
    0
  );
  const metricAverageValue = calculateAverage(dataPoints, metric);
  const numXValues = new Set(dataPoints?.map((d) => d.dataId)).size;

  return {
    totalInterviews,
    numXValues,
    metricAverageValue,
    metricTotalValue,
  };
};

type GetDashboardCardValuesForMetricArgs = {
  metric: MetricName;
  dataPoints: DataPoint[];
  deltaDataPoints?: DataPoint[];
};

type DashboardCardValues = {
  numXValues: number;
  metricAverageValue: number;
  delta?: number;
};

export const getDashboardCardValuesForMetric = (
  args: GetDashboardCardValuesForMetricArgs
): DashboardCardValues => {
  const { metric, dataPoints = [], deltaDataPoints = [] } = args;

  const { metricAverageValue, numXValues } = calculateMetricStats(
    dataPoints,
    metric
  );

  const {
    metricAverageValue: deltaMetricAverageValue,
    numXValues: deltaNumXValues,
  } = calculateMetricStats(deltaDataPoints, metric);

  const calculatedDelta =
    numXValues > 0 && deltaNumXValues > 0 && metricAverageValue !== 0
      ? metricAverageValue / deltaMetricAverageValue - 1
      : undefined;

  return {
    metricAverageValue,
    numXValues,
    delta: calculatedDelta,
  };
};

export const areFiltersOutdated = (search: string): boolean => {
  // we switched from uuid to email based interviewer filters
  // check for presence of "@" to determine if filters are outdated
  const interviewersFilterValues = new URLSearchParams(search).get(
    "interviewers"
  );
  return (
    !!interviewersFilterValues && interviewersFilterValues.indexOf("@") < 0
  );
};

export const getMockAlerts = (): Alert[] => [
  {
    id: uuidv4(),
    organizationId: "35bb5f6b-2733-464b-a561-35b4c941e824",
    message:
      "Candidates received < 30% talk time in interviews for 3 positions (40% of 99 interviews).",
    alertWeight: 40,
    alertType: "candidate_talk_time",
    alertValence: "negative",
    category: "candidate_experience",
    segment: "all",
    aggregation: "POSITION",
    aggregationIds: [
      "Account Executive (Demo) - No Department",
      "Content Marketing Manager - Marketing",
      "Head of Pawnee Parks - Customer Success Demo",
    ],
    alertOrder: 1,
    isNew: false,
  },
  {
    id: uuidv4(),
    organizationId: "35bb5f6b-2733-464b-a561-35b4c941e824",
    message:
      "Male candidates were asked 32% fewer questions than females in 2 departments (275 interviews).",
    alertWeight: 275,
    alertType: "count_interviewer_questions",
    alertValence: "negative",
    category: "dei",
    segment: "GENDER",
    aggregation: "DEPARTMENT",
    aggregationIds: ["Product", "Data Science"],
    alertOrder: 1,
    isNew: false,
  },
  {
    id: uuidv4(),
    organizationId: "35bb5f6b-2733-464b-a561-35b4c941e824",
    message:
      "A female candidate had 4+ interviews with no same-gender interviewers for the Senior Product Designer - Product  position.",
    alertWeight: 40,
    alertType: "gender_skewed_panel",
    alertValence: "negative",
    category: "dei",
    segment: "GENDER",
    aggregation: "CANDIDATE",
    aggregationIds: ["Yuki Wu"],
    alertOrder: 1,
    isNew: false,
  },
  {
    id: uuidv4(),
    organizationId: "35bb5f6b-2733-464b-a561-35b4c941e824",
    message:
      "Feedback was sent more than 24 hours after the interview in 2 departments (32% of 90 interviews).",
    alertWeight: 29,
    alertType: "submission_delay",
    alertValence: "negative",
    category: "talent_operations",
    segment: "all",
    aggregation: "DEPARTMENT",
    aggregationIds: ["Marketing", "Sales"],
    alertOrder: 1,
    isNew: false,
  },
  {
    id: uuidv4(),
    organizationId: "35bb5f6b-2733-464b-a561-35b4c941e824",
    message:
      'Female candidates were given a "hire" rating 35% more frequently than males in the Engineering department (29 interviews).',
    alertWeight: 29,
    alertType: "submission_is_positive",
    alertValence: "negative",
    category: "dei",
    segment: "GENDER",
    aggregation: "DEPARTMENT",
    aggregationIds: ["Engineering"],
    alertOrder: 1,
    isNew: false,
  },
  {
    id: uuidv4(),

    organizationId: "35bb5f6b-2733-464b-a561-35b4c941e824",
    message:
      "Feedback was not submitted in 9 departments (63% of 490 interviews).",
    alertWeight: 308,
    alertType: "submission_rate",
    alertValence: "negative",
    category: "talent_operations",
    segment: "all",
    aggregation: "DEPARTMENT",
    aggregationIds: [
      "Test Engineering",
      "Marketing",
      "Sales",
      "Data Science",
      "Demo",
      "Engineering",
      "Product",
      "Operations & People",
      "Customer Success Demo",
    ],
    alertOrder: 1,
    isNew: false,
  },
];

export const drillDownToMetricURL = (
  alert: Alert,
  dateParam: string
): string => {
  if (alert.alertType === "gender_skewed_panel") {
    const filters = alert.aggregationIds
      .map((id) => id.replaceAll(" ", "+"))
      .join("&candidate=");
    return `/search?candidate=${filters}&source=gender_skew_alert`;
  }

  const metric = {
    submission_rate: MetricName.ScorecardCompletionRate, // eslint-disable-line camelcase
    submission_delay: MetricName.ScorecardCompletionTime, // eslint-disable-line camelcase
    submission_is_positive: MetricName.PassRate, // eslint-disable-line camelcase
    is_late_start: MetricName.OnTimeInterviews, // eslint-disable-line camelcase
    candidate_talk_time: MetricName.CandidateTalkRatio, // eslint-disable-line camelcase
    count_interviewer_questions: MetricName.QuestionsAsked, // eslint-disable-line camelcase
  }[alert.alertType];

  if (!metric) {
    return "/insights";
  }

  let url = `/insights/${metric.toLowerCase()}?source=alert&${dateParam}`;
  const filters = alert.aggregationIds
    .map((id) => id.replaceAll(" ", "+"))
    .map((id) => id.replaceAll("&", "%26"))
    .join("%2C");
  const alertAggregation = alert.aggregation.toLocaleUpperCase();

  if (
    alert.segment.toLocaleLowerCase() ===
    AnalyticsDimension.Gender.toLocaleLowerCase()
  ) {
    url = `${url}&segment=${AnalyticsDimension.Gender}`;
  }

  if (alert.aggregationIds.length > 1) {
    url = `${url}&dimension=${alertAggregation}`;
    switch (alertAggregation) {
      case AnalyticsDimension.Department:
        url = `${url}&departments=`;
        break;
      case AnalyticsDimension.Interviewer:
        url = `${url}&interviewers=`;
        break;
      case AnalyticsDimension.Position:
        url = `${url}&positions=`;
        break;
      case AnalyticsDimension.JobStage:
        url = `${url}&stages=`;
        break;
      default:
        break;
    }
    return `${url}${filters}`;
  }
  if (alert.aggregationIds.length === 1) {
    const aggregationString = alert.aggregationIds[0]
      .replaceAll(" ", "+")
      .replaceAll("&", "%26");
    if (alertAggregation === AnalyticsDimension.Department) {
      return `${url}&dimension=${AnalyticsDimension.Position}&departments=${aggregationString}`;
    }
    if (alertAggregation === AnalyticsDimension.Position) {
      return `${url}&dimension=${AnalyticsDimension.Interviewer}&positions=${aggregationString}`;
    }
    return `${url}&dimension=${AnalyticsDimension.Interviewer}&interviewers=${aggregationString}`;
  }
  return `${url}&dimension=${alert.aggregation.toLocaleUpperCase()}`;
};

export const sortTableData = (
  data: ReportDataPoint[],
  sortColumn: ReportColumn,
  sortDirection: ReportSortDirection
): ReportDataPoint[] => {
  const sortableData = [...data];
  const safeNumber = (value: number | null | undefined): number => value || 0;
  const safeString = (value: string | null | undefined): string => value || "";

  if (sortDirection === "asc") {
    return sortableData.sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (aValue === null || bValue === null) {
        return aValue === null ? 1 : -1;
      }

      if (typeof aValue === "string" && typeof bValue === "string") {
        return safeString(aValue).localeCompare(safeString(bValue));
      }
      if (typeof aValue === "number" && typeof bValue === "number") {
        return safeNumber(aValue) - safeNumber(bValue);
      }
      return 0;
    });
  }

  return sortableData.sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (aValue === null || bValue === null) {
      return aValue === null ? 1 : -1;
    }

    if (typeof aValue === "string" && typeof bValue === "string") {
      return safeString(bValue).localeCompare(safeString(aValue));
    }
    if (typeof aValue === "number" && typeof bValue === "number") {
      return safeNumber(bValue) - safeNumber(aValue);
    }
    return 0;
  });
};

export const getLineChartData = (
  data: ReportDataPoint[],
  comparisonData: ReportDataPoint[]
): LineChartDataPoint[] => {
  const lineChartData: LineChartDataPoint[] = [];
  data.forEach((d, i) => {
    if (d.xLabel) {
      lineChartData.push({
        x: getUnixFromISO(d.xLabel),
        datum: d,
        compareDatum: comparisonData[i],
      });
    }
  });
  return lineChartData.sort((a, b) => a.x - b.x);
};

export const getUnixFromISO = (isoString: string): number => {
  const [year, month, day] = isoString
    .split("-")
    .map((num) => parseInt(num, 10));
  const date = new Date(year, month - 1, day);
  const unix = Math.floor(date.valueOf() / 1000);
  return unix;
};

export const getBucketDatesFromISO = (
  isoString: string,
  bucketInterval: string,
  bucketSize: number
): { start: Date; end: Date } => {
  const startDate = new Date(isoString);
  const endDate = new Date(isoString);

  switch (bucketInterval.toUpperCase()) {
    case "DAY":
      endDate.setDate(startDate.getDate() + bucketSize);
      break;
    case "WEEK":
      endDate.setDate(startDate.getDate() + bucketSize * 7);
      break;
    case "MONTH":
      endDate.setMonth(startDate.getMonth() + bucketSize);
      break;
    default:
      break;
  }
  return { start: startDate, end: endDate };
};

export const getValidDataForMetric = (
  data: ReportDataPoint[],
  metric: ReportColumn
): ReportDataPoint[] => {
  return data.filter((d) => typeof d[metric] === "number");
};

export const hasValidDataForMetric = (
  data: ReportDataPoint[],
  metric: ReportColumn
): boolean => {
  return getValidDataForMetric(data, metric).length > 0;
};

export const getAverageMetricValue = (
  data: ReportDataPoint[],
  metric: ReportColumn,
  options: { weightByCalls: boolean } = { weightByCalls: true }
): number | null => {
  const { weightByCalls } = options;

  const validData = getValidDataForMetric(data, metric);
  if (validData.length === 0) return null;
  const multiplier = weightByCalls
    ? (d: ReportDataPoint) => d.totalCalls as number
    : (d: ReportDataPoint) => 1;
  const denominator = validData.reduce((acc, d) => acc + multiplier(d), 0);
  return (
    validData.reduce(
      (acc, d) => acc + (d[metric] as number) * multiplier(d),
      0
    ) / denominator
  );
};

export const getAverageScore = (data: ReportDataPoint[]): number | null =>
  getAverageMetricValue(data, "score", { weightByCalls: true });
